import { Component } from '@angular/core';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { UserService } from '../../services/user.service';
import { SideNavInterface } from '../../interfaces/side-nav.type';

@Component({
    selector: 'app-sidenav',
    templateUrl: './side-nav.component.html'
})

export class SideNavComponent {

    public menuItems: any[]
    isFolded: boolean;
    isSideNavDark: boolean;
    isExpand: boolean;

    ROUTES: SideNavInterface[] = [{
        path: '/dashboard/',
        title: 'Dashboard',
        iconType: '',
        iconTheme: '',
        icon: '',
        submenu: [
        ]
    }, {
        path: '',
        title: 'Manobra',
        iconType: '',
        iconTheme: '',
        icon: '',
        submenu: [{
            path: '/manobra/pauta',
            title: 'Pauta de Serviços',
            iconType: '',
            icon: '',
            iconTheme: '',
            submenu: []
        }
        ]
    },  {
        path: '',
        title: 'Solicitações',
        iconType: '',
        iconTheme: '',
        icon: '',
        submenu: [{
            path: '/solicitacoes/manobra',
            title: 'Manobras',
            iconType: '',
            icon: '',
            iconTheme: '',
            submenu: []
        }
        ]
    }, {
        path: '',
        title: 'Empresa',
        iconType: '',
        iconTheme: '',
        icon: '',
        submenu: [{
            path: '/empresa/autoridade_maritima',
            title: 'Autoridade Marítima',
            iconType: '',
            icon: '',
            iconTheme: '',
            submenu: []
        }, {
            path: '/empresa/autoridade_portuaria',
            title: 'Autoridade Portuária',
            iconType: '',
            icon: '',
            iconTheme: '',
            submenu: []
        },
        ]
    }, {
        path: '',
        title: 'Local',
        iconType: '',
        iconTheme: '',
        icon: '',
        submenu: [
            {
                path: '/local/subzona',
                title: 'Subzona',
                iconType: '',
                icon: '',
                iconTheme: '',
                submenu: []
            }, {
                path: '/local/porto',
                title: 'Porto',
                iconType: '',
                icon: '',
                iconTheme: '',
                submenu: []
            }, {
                path: '/local/area',
                title: 'Áreas Operacionais',
                iconType: '',
                icon: '',
                iconTheme: '',
                submenu: []
            }, {
                path: '/local/local',
                title: 'Local',
                iconType: '',
                icon: '',
                iconTheme: '',
                submenu: []
            }
        ]
    }, {
        path: '',
        title: 'Embarcação',
        iconType: '',
        iconTheme: '',
        icon: '',
        submenu: [
            {
                path: '/embarcacoes/navios',
                title: 'Navios',
                iconType: '',
                icon: '',
                iconTheme: '',
                submenu: []
            }
        ]
    }, {
        path: '',
        title: 'Sistema',
        iconType: '',
        iconTheme: '',
        icon: '',
        submenu: [
            {
                path: '/sistema/usuario',
                title: 'Usuários',
                iconType: '',
                icon: '',
                iconTheme: '',
                submenu: []
            }
        ]
    }
    ]

    constructor(private themeService: ThemeConstantService, private _usuario: UserService) {

        _usuario.getPermissão().subscribe(retorno => {
            console.log(retorno)
        })
    }

    ngOnInit(): void {
        this.menuItems = this.ROUTES.filter(menuItem => menuItem);
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
        this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    }

    ngAfterViewInit(): void {
        /* Collapsed Menu dropdown */
        let submenus = document.querySelectorAll('.ant-menu li.ant-menu-submenu');
        let direction = document.querySelector('html').getAttribute('dir');
        submenus.forEach(item => {
            item.addEventListener('mouseover', function () {
                let menuItem = this;
                let menuItemRect = menuItem.getBoundingClientRect();
                let submenuWrapper = menuItem.querySelector('.side-nav ul.ant-menu-sub');
                if (submenuWrapper) {
                    submenuWrapper.style.top = `${menuItemRect.top}px`;
                    if (direction === 'ltr') {
                        submenuWrapper.style.left = `${menuItemRect.left + Math.round(menuItem.offsetWidth * 0.75) + 10}px`;
                    } else if (direction === 'rtl') {
                        submenuWrapper.style.right = `${Math.round(menuItem.offsetWidth * 0.75) + 10}px`;
                    }
                }

            })
        });
    }

    closeMobileMenu(): void {
        if (window.innerWidth < 992) {
            this.isFolded = false;
            this.isExpand = !this.isExpand;
            this.themeService.toggleExpand(this.isExpand);
            this.themeService.toggleFold(this.isFolded);
        }
    }
}
