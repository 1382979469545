import { Routes } from '@angular/router';
export const CommonLayout_ROUTES: Routes = [

    //Dashboard
    {
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
    },
    {
        path: 'empresa',
        loadChildren: () => import('../../empresas/empresas.module').then(m => m.EmpresasModule),
        data: {
          title: 'Empresa',
        },
    },
    {
        path: 'local',
        loadChildren: () => import('../../local/local.module').then(m => m.LocalModule),
        data: {
          title: 'Local',
        },
    },
    {
        path: 'embarcacoes',
        loadChildren: () => import('../../embarcacoes/embarcacoes.module').then(m => m.EmbarcacoesModule),
        data: {
          title: 'Embarcação',
        },
    },
    {
        path: 'solicitacoes',
        loadChildren: () => import('../../solicitacoes/solicitacoes.module').then(m => m.SolicitacoesModule),
        data: {
          title: 'Solicitações',
        },
    },
    {
        path: 'manobra',
        loadChildren: () => import('../../manobra/manobra.module').then(m => m.ManobraModule),
        data: {
          title: 'Manobra',
        },
    },
    {
        path: 'sistema',
        loadChildren: () => import('../../sistema/sistema.module').then(m => m.SistemaModule),
        data: {
          title: 'Sistema',
        },
    }
];
