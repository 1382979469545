import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../interfaces/user.type';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUser: User;

  constructor(private http: HttpClient, private _auteticacoa: AuthenticationService) {
    this.currentUser = this._auteticacoa.currentUserValue;
  }

  getPermissão() {
    return this.http.get<any>(environment.urlback + "/user/permissions")
  }

}
